<template lang="pug">
  .arrow-back(@click="$emit('back')")
    FaIcon(
      class="fa-rotate-270"
      :icon="'chevron-up'"
    )
    span {{ $t("company_system.back") }}
</template>

<script>
  export default {}
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .arrow-back
    cursor: pointer
    margin-left: 5px
    margin-top: 10px
    width: 60px

    ::v-deep
      svg
        path
          fill: $default-gray-medium

    span
      vertical-align: text-top
      margin-left: 5px
      color: $default-purple
      font-size: 0.9rem
</style>
